import * as React from "react";

export default function HomePage() {
  return (
    <div className="bg-gray-50 py-20 px-2 sm:px-6 lg:px-8">
      <div className="max-w-7xl mx-auto">
        {/* Header (changed blue to green) */}
        <div className="relative w-full">
          <svg
            className="absolute inset-0"
            style={{ width: "100%", height: "100%" }}
            xmlns="http://www.w3.org/2000/svg"
            preserveAspectRatio="xMidYMid slice"
            viewBox="0 0 1600 300" // Reduced height
          >
            <defs>
              <linearGradient
                id="bg-gradient"
                x1="0%"
                y1="0%"
                x2="100%"
                y2="100%"
              >
                <stop
                  offset="0%"
                  style={{ stopColor: "#f0fff4", stopOpacity: 1 }}
                />
                <stop
                  offset="100%"
                  style={{ stopColor: "#c6f6d5", stopOpacity: 1 }}
                />
              </linearGradient>
            </defs>

            <rect width="100%" height="100%" fill="url(#bg-gradient)" />

            <g opacity="0.1">
              <path
                d="M0,150 Q200,75 400,150 T800,150 T1200,150 T1600,150"
                stroke="#38a169"
                strokeWidth="8"
                fill="none"
              />
              <path
                d="M0,200 Q200,125 400,200 T800,200 T1200,200 T1600,200"
                stroke="#38a169"
                strokeWidth="8"
                fill="none"
              />
              <circle cx="400" cy="75" r="40" fill="#48bb78" />
              <circle cx="1200" cy="225" r="50" fill="#48bb78" />
              <rect x="800" y="100" width="80" height="80" fill="#48bb78" />
            </g>

            <g transform="translate(1500,25) rotate(45)">
              <rect x="0" y="0" width="30" height="45" fill="#2f855a" />
              <rect x="0" y="15" width="30" height="15" fill="#fff" />
              <circle
                cx="15"
                cy="22.5"
                r="11"
                fill="#48bb78"
                stroke="#fff"
                strokeWidth="2"
              />
            </g>
          </svg>

          <header
            className="relative text-center"
            style={{ paddingTop: "2rem", paddingBottom: "4rem" }}
          >
            <h1 className="text-4xl tracking-tight font-extrabold text-gray-900 sm:text-5xl md:text-6xl">
              <span className="block">Take Control of Your</span>
              <span className="block text-green-600">Financial Future</span>
            </h1>
            <p className="mt-3 max-w-md mx-auto text-base text-gray-500 sm:text-lg md:mt-5 md:text-xl md:max-w-3xl">
              Automatically track expenses and gain critical insights into your
              spending.
            </p>
          </header>
        </div>

        <main className="pb-20 pt-20">
          {/* Features section (changed blue to green) */}
          <section id="features" className="mb-20">
            <h2 className="text-3xl font-extrabold text-gray-900 text-center mb-8">
              Features
            </h2>
            <div className="grid grid-cols-1 gap-8 sm:grid-cols-2 lg:grid-cols-4">
              <Feature
                icon="📊"
                title="Budget Tracking"
                text="Automatically track expenses and get real-time updates on your spending."
              />
              <Feature
                icon="📅"
                title="Payment Management"
                text="Receive key insights into upcoming payments and manage subscriptions efficiently."
              />
              <Feature
                icon="🔍"
                title="Spending Analysis"
                text="Analyze your spending by category to make informed financial decisions."
              />
              <Feature
                icon="🤖"
                title="AI-Powered"
                text="Powered by AI to provide intelligent and personalized financial insights."
              />
            </div>
          </section>

          {/* AI Insights section (unchanged) */}
          <section id="ai-insights" className="mb-20">
            <h2 className="text-3xl font-extrabold text-gray-900 text-center mb-8">
              AI-Powered Insights
            </h2>
            <div className="grid grid-cols-1 gap-8 sm:grid-cols-2 lg:grid-cols-3">
              <InsightCard
                title="Spending Patterns"
                description="Our AI analyzes your transaction history to identify spending patterns and suggest areas for potential savings."
                icon="📉"
              />
              <InsightCard
                title="Budget Recommendations"
                description="Based on your income and expenses, we provide personalized budget recommendations to help you reach your financial goals."
                icon="💡"
              />
              <InsightCard
                title="Receipt Insights"
                description="Advanced AI algorithms automatically categorize line items on your receipts, splitting them into spending categories for detailed analysis."
                icon="🧾"
              />
            </div>
          </section>

          {/* Testimonials section (unchanged) */}
          <section id="testimonials" className="mb-20">
            <h2 className="text-3xl font-extrabold text-gray-900 text-center mb-8">
              What Our Users Say
            </h2>
            <div className="grid grid-cols-1 gap-8 sm:grid-cols-2 lg:grid-cols-3">
              <Testimonial
                quote="Sedona Finance has transformed the way I manage my finances!"
                author="Sarah W."
                role="New York, NY"
              />
              <Testimonial
                quote="I can't imagine life without Sedona Finance. It's a must-have tool."
                author="John D."
                role="Los Angeles, CA"
              />
              <Testimonial
                quote="The AI-driven insights are incredibly useful!"
                author="Emily R."
                role="Chicago, IL"
              />
              <Testimonial
                quote="Sedona Finance helped me pay off my debt in record time!"
                author="Michael S."
                role="Houston, TX"
              />
              <Testimonial
                quote="The spending pattern analysis is eye-opening. Highly recommended!"
                author="Laura M."
                role="Miami, FL"
              />
              <Testimonial
                quote="Finally, a budget app that's both powerful and easy to use!"
                author="Alex K."
                role="Seattle, WA"
              />
            </div>
          </section>

          {/* CTA section (changed blue to green) */}
          <section className="text-center">
            <a
              href="/get-started"
              className="inline-block bg-green-600 text-white font-semibold py-3 px-8 rounded-full text-lg shadow-md hover:bg-green-700 transition duration-300 transform hover:scale-105"
            >
              Get Started for Free
            </a>
          </section>
        </main>
      </div>
    </div>
  );
}

const InsightCard = ({ title, description, icon }) => (
  <div className="bg-white rounded-lg shadow-md p-6 flex flex-col items-center text-center">
    <div className="text-4xl mb-4">{icon}</div>
    <h3 className="text-xl font-semibold text-gray-900 mb-2">{title}</h3>
    <p className="text-gray-600">{description}</p>
  </div>
);

const Feature = ({ icon, title, text }) => (
  <div className="flex flex-col items-center text-center">
    <div className="flex items-center justify-center h-12 w-12 rounded-md bg-blue-500 text-white text-2xl mb-4">
      {icon}
    </div>
    <h3 className="text-lg font-medium text-gray-900 mb-2">{title}</h3>
    <p className="text-base text-gray-500">{text}</p>
  </div>
);

const Testimonial = ({ quote, author, role }) => (
  <div className="bg-white shadow-lg rounded-lg p-6">
    <blockquote className="text-gray-600 italic mb-4">
      &ldquo;{quote}&rdquo;
    </blockquote>
    <footer>
      <div className="font-semibold text-gray-900">{author}</div>
      <div className="text-gray-500 text-sm">{role}</div>
    </footer>
  </div>
);