import React, { useState } from "react";
import { Route, Routes, Link } from "react-router-dom";
import "./App.css";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import HomePage from "./pages/Home";
import Icon from './SF-Logo.png';
import RegistrationPage from "./pages/Registration";
import TermsOfService from "./pages/TermsOfService";

function App() {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  return (
    <div className="min-h-screen bg-gradient-to-b from-gray-100 to-gray-200">
      <nav className="bg-white shadow-lg sticky top-0 z-10">
        <div className="container mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex justify-between items-center py-4">
            <div className="flex justify-start lg:w-0 lg:flex-1">
              <a href="/" className="text-2xl font-bold text-green-600">
                <img src={Icon} alt="Sedona Finance Icon" className="h-16 inline-block mr-2" />
              </a>
            </div>
            <div className="-mr-2 -my-2 md:hidden">
              <button
                type="button"
                className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-green-500"
                aria-expanded={isMobileMenuOpen}
                onClick={toggleMobileMenu}
              >
                <span className="sr-only">Open menu</span>
                <svg
                  className="h-6 w-6"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M4 6h16M4 12h16M4 18h16"
                  />
                </svg>
              </button>
            </div>
            <div className="hidden md:flex space-x-10 items-center">
              <a
                href="/"
                className="text-base font-medium text-gray-500 hover:text-gray-900"
              >
                Home
              </a>
              <a
                href="/#features"
                className="text-base font-medium text-gray-500 hover:text-gray-900"
              >
                Features
              </a>
              <a
                href="/#testimonials"
                className="text-base font-medium text-gray-500 hover:text-gray-900"
              >
                Testimonials
              </a>
              <a
                href="/get-started"
                className="text-base font-medium text-gray-500 hover:text-gray-900"
              >
                Get Started
              </a>
              <a
                href="https://app.sedonafinance.com"
                className="ml-8 inline-flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-green-600 hover:bg-green-700"
              >
                Login
              </a>
            </div>
          </div>
        </div>

        {/* Mobile Menu */}
        {isMobileMenuOpen && (
          <div className="md:hidden">
            <div className="px-2 pt-2 pb-3 space-y-1 sm:px-3">
              <a
                href="/"
                className="block px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:text-gray-900 hover:bg-gray-50"
              >
                Home
              </a>
              <a
                href="/#features"
                className="block px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:text-gray-900 hover:bg-gray-50"
              >
                Features
              </a>
              <a
                href="/#testimonials"
                className="block px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:text-gray-900 hover:bg-gray-50"
              >
                Testimonials
              </a>
              <a
                href="/get-started"
                className="block px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:text-gray-900 hover:bg-gray-50"
              >
                Get Started
              </a>
              <a
                href="https://app.sedonafinance.com"
                className="block w-full text-center px-3 py-2 rounded-md text-base font-medium text-white bg-green-600 hover:bg-green-700"
              >
                Login
              </a>
            </div>
          </div>
        )}
      </nav>

      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/terms-of-service" element={<TermsOfService />} />
        <Route path="/get-started" element={<RegistrationPage />} />
      </Routes>

      <footer className="bg-gray-800 text-white py-8">
        <div className="container mx-auto px-4">
          <div className="flex flex-wrap justify-between items-center">
            <div className="w-full md:w-1/3 text-center md:text-left mb-6 md:mb-0">
              <h3 className="text-2xl font-bold">Sedona Finance</h3>
              <p className="mt-2">Take control of your financial future</p>
            </div>
            <div className="w-full md:w-1/3 text-center md:text-right">
              <Link to="/privacy-policy" className="text-white hover:text-green-400 mr-4">
                Privacy Policy
              </Link>
              <Link to="/terms-of-service" className="text-white hover:text-green-400">
                Terms of Service
              </Link>
            </div>
          </div>
          <div className="mt-8 text-center text-sm">
            <p>&copy; 2024 Sedona Finance. All rights reserved.</p>
          </div>
        </div>
      </footer>
    </div>
  );
}

export default App;
