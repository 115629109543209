import React, { useState } from 'react';
const RegistrationPage = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle form submission logic here
    console.log('Form submitted:', { name, email });
  };

  const handleGoogleSignUp = () => {
    // Implement Google sign-up logic
    console.log('Sign up with Google');
  };

  const handleFacebookSignUp = () => {
    // Implement Facebook sign-up logic
    console.log('Sign up with Facebook');
  };

  return (
    <div className="bg-gray-50 py-20 px-2 sm:px-6 lg:px-8">
      <div className="max-w-md mx-auto mt-10 p-6 bg-white rounded-lg shadow-md">
        <h2 className="text-2xl font-bold mb-6 text-center">Create an Account</h2>
        <div className="mb-6 p-4 bg-yellow-100 border-l-4 border-yellow-500 text-yellow-700">
          <p className="font-bold">Registration is currently closed</p>
          <p>We'll be opening registration soon. Please check back later!</p>
        </div>

        <form onSubmit={handleSubmit} className="space-y-4">
          <div>
            <label htmlFor="name" className="block mb-1 font-medium">
              Name:
            </label>
            <input
              type="text"
              id="name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              required
              className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
          </div>
          <div>
            <label htmlFor="email" className="block mb-1 font-medium">
              Email:
            </label>
            <input
              type="email"
              id="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
              className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
          </div>
          <button
            type="submit"
            className="w-full bg-blue-500 text-white py-2 px-4 rounded-md hover:bg-blue-600 transition duration-300"
            disabled={true}
          >
            Sign Up
          </button>
        </form>
        <div className="mt-6 space-y-3">
          <button
            onClick={handleGoogleSignUp}
            className="w-full bg-red-500 text-white py-2 px-4 rounded-md hover:bg-red-600 transition duration-300"
            disabled={true}
          >
            Sign up with Google
          </button>
          <button
            onClick={handleFacebookSignUp}
            className="w-full bg-blue-600 text-white py-2 px-4 rounded-md hover:bg-blue-700 transition duration-300"
            disabled={true}
          >
            Sign up with Facebook
          </button>
        </div>
      </div>
    </div>
  );
};

export default RegistrationPage;
